<template>
  <div>
    <div class="mobile-version">
      <div class="container">
        <!--LOGO--->
        <div class="row">
          <div  class="col d-flex justify-content-center">
            <router-link :to="{ name: routeNames.HOME}">
              <img alt="binliang-alexander-peng-logo" :src="cdnBaseUrl+'logo_resized.jpg'" style="height: 96px;">
            </router-link>
          </div>
        </div>
        <div class="row" >
          <div class="col-2" v-if="!inCheckout">
            <button :aria-expanded="visible ? 'true' : 'false'"
                    @click="visible = !visible"
                    aria-controls="collapse-nav-menu"
                    data-bs-toggle="collapse" href="#collapse-nav-menu"
                    class="navbar-toggler"
                    type="button"
            >
              <BurgerIcon v-if="!visible"></BurgerIcon>
              <CrossIcon v-if="visible"></CrossIcon>
            </button>
          </div>


          <div  v-if="!inCheckout" class="col-8 text-center"  style="padding-top: 10px;">
            <p class="has-text-centered cinzel bap-title">BINLIANG ALEXANDER PENG</p>
          </div>
          <div  v-if="inCheckout" class="col-12 text-center"  style="padding-top: 10px;">
            <p class="has-text-centered cinzel bap-title">BINLIANG ALEXANDER PENG</p>
          </div>

          <div class="col-1" v-if="!inCheckout">
            <router-link :to="{ name: routeNames.CONTACT_US}">
              <i class="bi bi-telephone h3 bap-pink"></i>
            </router-link>
          </div>

        </div>
        <div id="collapse-nav-menu" v-if="visible">
          <div  class="list-group table-borderless">
            <div class="list-group-item borderless"
                 @click="visible = !visible"
                 v-bind:id="route.id"
                 v-bind:key="route.id"
                 v-for="route in links"
            >
              <router-link
                  :to="{ name: route.routeName}"
                  class="dropdown-item"
                  exact-active-class="exact-active"
              >
                <span class="bap-title">{{ route.name }}</span>
              </router-link>
            </div>
            <div class="list-group-item borderless"><LanguageSwitch></LanguageSwitch></div>

          </div>
        </div>
      </div>
    </div>

    <!-- Desktop -->
    <div  class="container desktop-version bap-pink">
      <div class="row">
        <div class="col"><LanguageSwitch></LanguageSwitch></div>
        <div  class="col d-flex justify-content-center">
          <router-link :to="`/`">
            <img alt="binliang-alexander-peng-logo" :src="cdnBaseUrl+'logo_resized.jpg'" style="height: 116px;">
          </router-link>
        </div>
        <div class="col"></div>
      </div>
      <div class="row">
        <div  class="col d-flex justify-content-center">
          <p class="has-text-centered cinzel bap-title">BINLIANG ALEXANDER PENG</p>
        </div>
      </div>

      <div class="row">
        <div class="col borderless" v-for="route in links_desktop"
             v-bind:id="route.id"
             v-bind:key="route.id"
             @click="visible = !visible">
          <router-link
              :to="{ name: route.routeName}"
              class="dropdown-item "
              exact-active-class="exact-active"
          >
            {{ route.name }}
          </router-link>
        </div>
        <div class="col-1" v-if="!inCheckout">
          <router-link :to="{ name: routeNames.CONTACT_US}">
            <i class="bi bi-telephone h3 bap-pink"></i>
          </router-link>
        </div>

      </div>
      <br>
    </div>
  </div>
</template>

<script>
import RouteNames from "@/routeNames";
import AuthHelper from "@/authHelper";
import CrossIcon from "@/components/CrossIcon";
import BurgerIcon from "@/components/BurgerIcon";
import LanguageSwitch from "@/components/LanguageSwitch";

export default {
  name: "NavBar",
  components: {LanguageSwitch, BurgerIcon, CrossIcon},
  props : {
    inCheckout: Boolean
  },
  data() {
    return {
      cdnBaseUrl: process.env.VUE_APP_CDN_BASE_URL,
      routeNames : RouteNames,
      cartRouteName :RouteNames.CART,
      visible: false,
      links: [
        {
          id: 1,
          name: this.$t('navbar.item.collections'),
          routeName: RouteNames.STORE
        },
        {
          id: 2,
          name: this.$t('navbar.item.theJeweler'),
          routeName: RouteNames.THE_JEWELLER
        },
        {
          id: 3,
          name: this.$t('navbar.item.artisanal'),
          routeName: RouteNames.ARTISANAL
        },

        {
          id: 4,
          name: this.$t('navbar.item.surmesure'),
          routeName: RouteNames.SUR_MESURE
        },
        {
          id: 5,
          name: this.$t('navbar.item.events'),
          routeName: RouteNames.EVENTS
        },
        {
          id: 6,
          name: this.$t('navbar.item.contact'),
          routeName: RouteNames.CONTACT_US
        },
        //{
        //  id: 6,
        //  name: "Mon Compte",
        // routeName: RouteNames.MY_ACCOUNT
        //},
      ],
      links_desktop: [
        {
          id: 1,
          name: this.$t('navbar.item.collections'),
          routeName: RouteNames.STORE
        },
        {
          id: 2,
          name: this.$t('navbar.item.theJeweler'),
          routeName: RouteNames.THE_JEWELLER
        },
        {
          id: 3,
          name: this.$t('navbar.item.artisanal'),
          routeName: RouteNames.ARTISANAL
        },

        {
          id: 4,
          name: this.$t('navbar.item.surmesure'),
          routeName: RouteNames.SUR_MESURE
        },

      ],

    }
  },
  methods: {
    dropdownClicked: function () {
      this.isActive = !this.isActive;
    },
    onClickLogout(){
      AuthHelper.signout().then(()=>{
        alert("signout")
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.borderless {
  border: none;
}
a.dropdown-item.exact-active.router-link-active {
  color: #c83131 !important;
}
a.dropdown-item {
  color: #d67a83 !important;
}
</style>
