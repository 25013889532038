<template>
  <i class="bi bi-x"></i>
</template>


<script>

export default {
  name: "CrossIcon",
  components: {}
}
</script>

<style scoped>
.burgerMenu {
  height: 2rem;
  width: 2rem;
  fill: currentColor;
}

</style>
