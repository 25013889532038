<template>

  <svg version="1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" enable-background="new 0 0 48 48">
    <path fill="#FF9800" d="M22,38c-4.8,0-5-7-5-7v-6h10v6C27,31,26.8,38,22,38z"/>
    <g fill="#FFA726">
      <circle cx="31" cy="19" r="2"/>
      <circle cx="13" cy="19" r="2"/>
    </g>
    <path fill="#FFB74D" d="M31,13c0-7.6-18-5-18,0c0,1.1,0,5.9,0,7c0,5,4,9,9,9s9-4,9-9C31,18.9,31,14.1,31,13z"/>
    <path fill="#424242" d="M22,4c-6.1,0-10,4.9-10,11c0,0.8,0,2.3,0,2.3l2,1.7v-5l12-4l4,4v5l2-1.7c0,0,0-1.5,0-2.3c0-4-1-8-6-9l-1-2 H22z"/>
    <g fill="#784719">
      <circle cx="26" cy="19" r="1"/>
      <circle cx="18" cy="19" r="1"/>
    </g>
    <path fill="#009688" d="M27,31L27,31c0,0-1.8,2-5,2s-5-2-5-2S6,33,6,44h32C38,33,27,31,27,31z"/>
    <g fill="#FF9800">
      <rect x="36.1" y="6.1" transform="matrix(.707 .707 -.707 .707 19.787 -25.77)" width="9.9" height="9.9"/>
      <rect x="36" y="6" width="10" height="10"/>
    </g>
    <circle fill="#FFEB3B" cx="41" cy="11" r="3"/>
  </svg>
</template>

<script>
export default {
  name: "MaleClientIcon"
}
</script>

<style scoped>

</style>