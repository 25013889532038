import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap"
import i18n from './i18n'
require('@/assets/main.scss');
import 'maz-ui/css/main.css'
// vue-meta
import { plugin as vueMetaPlugin,createMetaManager } from 'vue-meta'
import { createPinia } from 'pinia'
const pinia = createPinia()

let app = createApp(App)
app.use(pinia)
app.use(i18n).use(router).mount('#app')
app.use(vueMetaPlugin)
const metaManager = createMetaManager()

app.use(metaManager)

app.config.globalProperties.$lang = process.env.VUE_APP_I18N_LOCALE
app.config.globalProperties.$siteName = "Binliang Alexander Peng"
app.config.globalProperties.$cdnBaseUrl = process.env.VUE_APP_CDN_BASE_URL

