<template>
  <i class="bi bi-list h3 bap-pink" ></i>
</template>

<script>

export default {
  name: "BurgerIcon",
  components:{}
}
</script>

<style scoped>
.crossMenu {
  height: 2rem;
  width: 2rem;
  fill: currentColor;
}

</style>
