export default {
  "languagePref": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
    "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous voir ce site en Français !"])},
    "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])}
  },
  "cgv": {
    "iAcceptThe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept the"])},
    "cgv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["general conditions of sale"])},
    "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In order to continue you must accept the general conditions of sale."])}
  },
  "press": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["They talk about us..."])}
  },
  "wedding": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bespoke Engagement Rings and Wedding Bands"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production of wedding bands and unique custom-made engagement rings."])},
    "headText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For me, each person is unique and each union of two beings is a miracle of life, so the jewels that will bear witness to this unparalleled event must also be unique."])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact the jeweler"])},
    "howItWorks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How does it work?"])},
    "stepCall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's call"])},
    "stepCallExplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Determine your project with the jeweler."])},
    "stepAppointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First appointment"])},
    "stepAppointmentExplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We choose together the style of the jewel, the stone we are going to use, the jeweler makes one or more drawings according to your desire"])},
    "stepProto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prototype"])},
    "stepProtoExplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We present you with a prototype that you validate, then we launch production in the workshop"])},
    "stepShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
    "stepShippingExplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We present you the long-awaited jewel in its case in Paris or we offer you the postal delivery (insured, against signature)"])},
    "creationExample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example of creations"])},
    "quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality artisanal production"])},
    "qualityExplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handmade in Paris using traditional methods."])},
    "privileged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privileged treatment"])},
    "privilegedExpl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are not a number. We reserve you special treatment for the realization of your jewel."])},
    "precious": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certified precious materials"])},
    "preciousExpl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our gold and silver suppliers are certified by the \"Responsible Jewelery Council\""])}
  },
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading... Please wait."])},
  "progress": {
    "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Address"])},
    "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Delivery"])},
    "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Payment"])}
  },
  "errorMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occured, please try again later."])},
  "eventsPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binliang Alexander Peng will be present at the following events:"])},
    "rev1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révélations is held every two years in Paris."])},
    "rev2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are honoured to have been selected by the Jury to present our creations."])},
    "rev3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The exhibition is <strong>from 7 June to 11 June.</strong>"])},
    "buttonMoreInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exhibition Website"])},
    "pastTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Past Events"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "milanoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milano Jewellery Week, 2022"])},
    "milano1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have participated to the Jewellery Week in Milano."])},
    "milano2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The exhibition took place in the city center from 20th to 23rd October 2022."])},
    "chantalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galery Psychée Exhibition, 2022"])},
    "chantal1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We were pleased to exhibit some of our work at the Galery from 29th Sep to 3rd Oct 2022."])},
    "chantal2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exhibition title: 'In the Stomach of a Monster'"])},
    "chantal3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The galery is located 18 Rue du Pont Louis-Philippe, 75004 Paris"])},
    "chantalVideoCredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video credit: Galery Psychée"])},
    "timelessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeless Jewels, 2021"])},
    "timeless1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We were happy to be able to present our work at Timeless Jewels."])},
    "timeless2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The exhibit took place in November 2021."])},
    "timeless3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We were welcomed in the prestigious 'Hotel de l'industrie' at Saint-Germain des Près in Paris "])}
  },
  "invitationForm": {
    "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each creation of Binliang is unique so we cannot serve all clients."])},
    "line2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please share your details below to express your interest in Binliang's exclusive creations:"])}
  },
  "homeView": {
    "latestCreations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest creations"])},
    "seeMoreCreations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See more creations"])},
    "eventsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
    "eventsExpl1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have been honored to be selected for the latest <strong>Revelations</strong> exhibition !"])},
    "eventsExpl2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revelations is the parisian International Biennial of Crafts and Creation."])},
    "eventsExpl3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find all our past exhibitions on this dedicated page."])},
    "artisanJeweller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artisan jeweler"])},
    "altMainPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binliang Alexander Peng Jeweler Parisian jeweler making bespoke pieces"])},
    "bespoke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bespoke"])},
    "ourStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creations"])},
    "wedding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wedding"])},
    "weddingExpl1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We design engagement rings and wedding bands together."])},
    "weddingExpl2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A unique and precious model, like your love"])},
    "knowMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
    "ourCollections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Collections"])},
    "ourCollectionsExpl1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We produce all our pieces in our Parisian workshop."])},
    "ourCollectionsExpl2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our collections and unique pieces are on sale on our site."])},
    "seeCollections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See our collections"])},
    "theBespoke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Made-to-Measure"])},
    "theBespokeExpl1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build an exceptional jewel made by hand by a craftsman."])},
    "theBespokeExpl2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What better than a jewel to say I love you to this person who is so precious to you?"])},
    "discoverHandmade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover our pieces produced entirely by hand"])},
    "conceiveUnique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design a unique piece of jewelry."])},
    "bespokeArtisan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build your jewelry handmade by a craftsman."])},
    "beOriginal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be original and look like no one else."])},
    "artisanalProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An Artisanal Production"])},
    "madeInParis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All the pieces are handmade by the artisan jeweler in his Parisian workshop. Member of the Paris Chamber of Trades."])},
    "atelier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workshop"])},
    "freeShip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free delivery and returns"])},
    "freeShipDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard and free delivery. Returns are also free if you are not entirely satisfied with the jewelry."])},
    "collections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collections"])},
    "surMesureJewels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bespoke"])},
    "surMesureExpl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our workshop can make the jewel of your dreams. We put our workshop and our team at your disposal to build the piece of your dreams with you."])},
    "appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment"])}
  },
  "exclusivity": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Important Information"])},
    "expl1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binliang's creations are selectively offered, appealing to connoisseurs with a keen interest in our unique craftsmanship."])},
    "expl2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due to the unique nature of Binliang's pieces, we extend the opportunity to acquire them by invitation only."])},
    "expl3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are captivated by a piece and wish to express your interest, we invite you to request an invitation. "])}
  },
  "jeweller": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My History"])},
    "myInspiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Inspiration"])}
  },
  "navbar": {
    "item": {
      "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
      "theJeweler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Jeweler"])},
      "collections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creations"])},
      "artisanal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artisanal Production"])},
      "surmesure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bespoke"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
    }
  },
  "footer": {
    "copyright": {
      "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright 2022"])},
      "line2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights reserved"])}
    },
    "aboutus": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Artisanal Firm"])},
      "companyHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The jeweler"])},
      "productionProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Craft production"])},
      "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press"])},
      "bespoke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bespoke"])}
    },
    "apropos": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
      "legalMentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Notices"])},
      "cgv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General terms and conditions of sale"])},
      "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
      "retours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free returns"])},
      "sitePlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitemap"])},
      "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment 3x free of charge"])}
    }
  },
  "pressView": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For any request, do not hesitate to write to us at the following address: <a href=\"mailto:presse", "@", "binliang-alexander-peng.fr\">presse", "@", "binliang-alexander-peng.fr</a>."])}
  },
  "loadingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading... Please wait"])},
  "newsletter": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsletter:"])},
    "reassurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover our latest creations exclusively:"])}
  },
  "ratings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer reviews"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5/5 on Google"])},
    "avis1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A jeweler who listens and is empathetic (limit telepathic), very attached to carving a part of our personality or our past on a jewel. The ring always suits me with the months, whether for the metal or stone, unique, personal and in my image. Essential!"])},
    "avis2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I entrusted Binliang Alexander Peng with the creation of my first custom-made ring. Binliang is a great professional. His stones are superb and the jewelry is made with originality and perfection. Very attentive to his customers, Binliang knows how to accompany and reassure its customers as much as to make them dream. I recommend 100% for a unique jewel that will represent you."])},
    "avis3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alexander made a custom-made ring for me, a unique moment for a unique piece! Alexander has the gift of telling stories for each of his creations, giving them a magnificent poetic dimension. It was for me a very great experience. I warmly recommend it!"])},
    "avis4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I discovered Binliang's creations on Insta and immediately fell in love with them! What beautiful stones, original models and, at the same time, easy to wear on a daily basis. And, above all, you will not risk see similar ones on someone else. On the other hand, I had the chance to meet Binliang personally, to discuss with him and to choose one of these beautiful Gipsy rings. For me, Binliang is the most beautiful meeting of the year 2020!"])},
    "avis5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initially there is a pretty aquamarine from an old jewel, received as a gift...\n            Then, the discovery on Instagram of a jeweler, Binliang.alexander.peng.\n            His artistic universe touched me, I like poetry, the creativity of his jewelry... So I called on his services to have a unique ring.\n            Then came the meeting of this artist, a beautiful person, a young man passionate about his profession, who wants to share his passion and his universe. I was sure that I was not mistaken, that I would have the ring that suits me....\n            And finally.... I got it!\n            A marvel!!!! 😍😍😍😍😍.\n            Thanks again to the artist 😉😍😍😍"])},
    "avis6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binliang is an outstanding jeweler. He is very professional and very talented in the creation of unique and original jewellery. He is very attentive to the desires and needs of his customers. made an extraordinary pendant that delighted me to the point. I can't wait to work with him again. I recommend him with my eyes closed if you want an original, unique and very good quality creation. Many thanks again my friend Binliang ."])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source: Google.com"])}
  },
  "product": {
    "badgeExclusive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One of a kind, Exclusive collection"])},
    "orderByPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order By Phone"])},
    "alreadySold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This product has already been sold!"])},
    "threeTimesPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3x no charge"])},
    "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to cart"])},
    "inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inquiry"])},
    "ctaPDP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request an Invitation"])},
    "inquiryFormTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inquiry"])},
    "priceUponRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price On Request"])},
    "requestPrivateViewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Private Viewing"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover"])},
    "availableForOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available on order"])},
    "contactUsToMake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us to have this jewel made"])},
    "addToCartError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred, we apologize"])},
    "addToCartSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added to cart!"])},
    "inquiryFirstParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This piece has a story I'm eager to share with you. Let's have a personal conversation about its journey and the craftsmanship behind it."])},
    "inquiryNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is your name ?"])},
    "inquirySend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Your Request"])},
    "inquiryPrivacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Guaranteed: We Respect and Protect Your Information. We Will Never Share it With Others."])},
    "inquiryPhoneLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best Number to Reach You"])}
  },
  "shipping": {
    "deliveryAtAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery to address:"])},
    "basketText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your basket"])},
    "address": {
      "updateLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change address"])}
    },
    "labelMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home delivery:"])},
    "cartEmpty": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your basket is empty"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our collections"])}
    },
    "nextStepButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate delivery"])},
    "preInvoice": {
      "totalArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total articles incl. VAT"])},
      "totalShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total shipping costs including tax"])},
      "grandTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL including tax"])},
      "couponWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have a coupon code? You can add it in the next step."])}
    }
  },
  "address": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Name"])},
    "helpType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start typing the first line of your address:"])},
    "findYours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find your address"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Address"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "complement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complement (optional)"])},
    "postCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "errorPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid phone number, please correct"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
    "buttonValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deliver to this address"])},
    "phoneCodeCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Code"])},
    "phoneCountrySelectError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a country"])},
    "phoneLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "phoneExample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example:"])},
    "phoneErrorValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number is not valid"])},
    "fillManual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your address manually"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])}
  },
  "payment": {
    "wire": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment by bank transfer"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send your transfer"])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will only process your order when your transfer has been received by our bank."])},
      "ourRib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our RIB"])},
      "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank:"])}
    },
    "selector": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choice of payment method"])},
      "creditCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank card"])},
      "paypal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paypal"])},
      "wire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Transfer"])},
      "cheque": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check"])}
    }
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "doubt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to ask us a question?"])},
    "doubt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you hesitating between several pieces?"])},
    "doubt3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want a custom-made product? Do not hesitate to contact us."])},
    "byChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Chat"])},
    "openChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Chat"])},
    "byPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By phone"])},
    "openingHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our atelier phone is open <strong>every day from 10am to 7pm (Paris Time)</strong>"])},
    "byEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By Email"])},
    "ourEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here is our email address: support", "@", "binliang-alexander-peng.fr"])},
    "byForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via our contact form"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message:"])},
    "messagePlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is your project? (optional)"])},
    "itsFree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The quote is free!"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your request has been registered"])},
    "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The jeweller will contact you as soon as possible."])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the meantime, don't hesitate to take a look at my creations"])},
    "privateViewing": {
      "messageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
      "messagePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional"])}
    },
    "inquiry": {
      "messagePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional"])}
    }
  },
  "bespoke": {
    "contactModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "contactModalClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bespoke Jewelry"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "artisanalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A 100% Artisanal Production"])},
    "artisanal1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All the pieces are handmade by the artisan jeweler in his Parisian workshop."])},
    "artisanal2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Member of the Paris Chamber of Trades."])},
    "artisanal3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artisan"])},
    "why": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why call on us?"])},
    "howItWorks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How does it work?"])}
  },
  "cart": {
    "removeFromSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "subTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-Total"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery"])},
    "offered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offered"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])},
    "unitPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit price"])},
    "yourBasket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Basket"])},
    "empty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your cart is currently empty"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take a look at our collections"])},
      "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our collections"])}
    },
    "toastSuccessUpdate": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success!"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your cart has been successfully updated!"])}
    },
    "freeShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standard delivery is free."])},
    "returnPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free returns"])},
    "nextStepButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
    "paymentIsSecured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure payment"])},
    "needHelp": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you need help?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have any doubts? Would you like to discuss with the jeweler?"])},
      "callButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+33 (0)1 23 45 67 89"])}
    },
    "itemsCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " piece"]), _normalize([_interpolate(_named("count")), " pieces"])])}
  },
  "meta": {
    "addressCheckout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order: Shipping address - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order: Delivery address"])}
    },
    "artisanalPresentation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Craft Production - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handmade pieces of jewelry. Lost wax then metal work, manufacture of a mould, crimping and polishing"])}
    },
    "events": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next event: Revelations 2023 in Paris at Grand Palais"])}
    },
    "cartV2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basket - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Cart"])}
    },
    "CGV": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General terms and conditions of sale - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions of the Binliang Alexander Peng Shop"])}
    },
    "companyHistory": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History of the jeweler - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binliang is a Parisian jeweler discover its history"])}
    },
    "contactUs": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact the artisan jeweler to make a custom jewel"])}
    },
    "homeView": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binliang Alexander Peng - Parisian Artisan Jeweler"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binliang Alexander Peng, Parisian Artisan Jeweler from Place Vendôme. Collections and tailor-made creations."])}
    },
    "legalNotices": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Notice - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binliang Alexander Peng, Legal Notice"])}
    },
    "mariageView": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bespoke Fillançailles and Wedding Rings - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bespoke fillançailles and wedding rings, Parisian jeweler"])}
    },
    "orderSuccess": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order validated! - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Command validated"])}
    },
    "paymentCheque": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment by Check - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment by Check"])}
    },
    "paymentSelection": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choice of payment method - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order - Choice of payment method"])}
    },
    "paymentPaypal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order - Payment by Paypal - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order - Payment by Paypal"])}
    },
    "paymentStripe": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure payment of your order - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order - Payment by secure card"])}
    },
    "paymentWire": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment by Bank Transfer - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order - Payment by Bank Transfer"])}
    },
    "pressView": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press Contact - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On this page our press contact."])}
    },
    "returnConditions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Return Conditions - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free returns for 30 days after receipt of order."])}
    },
    "shippingConditions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free standard shipping."])}
    },
    "shippingChoose": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choice of delivery method - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choice of delivery method"])}
    },
    "storeMain": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artisan jewelry boutique - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handmade jewelry in our workshop. Rings, bracelets in 18-carat gold and silver"])}
    },
    "bespokePage": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bespoke Jewelery Creation - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation of custom jewelry by a Parisian jeweler"])}
    }
  },
  "cookieConsent": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept"])},
    "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline"])},
    "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
    "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
    "partnerListTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Partners:"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie Consent"])},
    "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use cookies to improve your experience on our site. By continuing, you agree to our use of cookies."])},
    "metaDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use Meta's Pixel for improved ad targeting and site analytics, enhancing your experience and ensuring relevant content and ads."])},
    "tiktok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use Tiktok Events API for improved ad targeting and site analytics, enhancing your experience and ensuring relevant content and ads."])},
    "mixpanelDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use Mixpanel to better understand how you navigate this website."])},
    "hotjarDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use Hotjar to better understand how you navigate this website."])},
    "googleDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use Google Tag Manager to better understand how you navigate this website, and to improve targeting of ads."])}
  },
  "onboarding": {
    "seeMyInstagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Instagram account"])},
    "latestCreations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some Creations"])},
    "latestVideos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some Creations Explained"])},
    "seeMoreOnSocialInstaAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["my Instagram account"])},
    "seeMoreOnSocial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can see more creation presentations on"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin Your Journey"])},
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the World of Binliang: A Haven of Unique Handcrafted Jewelry"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello! It's a pleasure to have you here. I'm Binliang, the sole creator behind each jewelry piece you'll find. In my Atelier, every creation is more than jewelry; it's a personal expression of individuality. I'm dedicated to crafting pieces that aren't just beautiful but tell a story – your story."])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What 'Bespoke' Truly Means to Me:"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bespoke in my atelier is a deeply personal process. It's about bringing your vision to life in a way that resonates with my creative philosophy. When your ideas align with my artistic spirit, they are crafted into a piece that is uniquely yours, radiating personal charm and creativity"])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Creation Process:"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our journey begins with a conversation, where your dreams and ideas are the stars. If we find a shared vision, I personally take your idea through the journey of handcrafting, combining your vision with my dedication to traditional craftsmanship.\n\n Our journey begins with a conversation, where your dreams and ideas are the stars. If we find a shared vision, I personally take your idea through the journey of handcrafting, combining your vision with my dedication to traditional craftsmanship."])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intimate Service and Privacy:"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At my atelier, your experience and privacy are of the utmost importance. I offer an intimate, personalized service, focusing on crafting a piece that reflects your unique essence and story."])},
    "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choosing a Creation:"])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choosing a piece from my atelier means investing in something that's not just unique, but also a reflection of a shared creative spirit. It's about owning a piece of jewelry that's imbued with personal care and an artistic touch."])},
    "title6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Experiences:"])},
    "title7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An Invitation to Creative Discovery:"])},
    "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celebrating exclusivity in every creation,I invite you to share your inspiration with me.However, please note that as an artist who values the essence of selectivity, I carefully choose the projects I undertake. Together, we can explore if your vision aligns with my craft, leading to the creation of a truly unique, bespoke piece."])},
    "title8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handcrafted Elegance: The Artisan Touch"])},
    "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each piece is handcrafted, a method that underscores my dedication to authentic craftsmanship. I consciously steer clear of 3D modeling techniques, favoring the intimate connection and precision that only manual labor can impart. This approach ensures that every creation is not just unique, but also infused with a personal touch and a level of skill that only an artist's hand can provide, forging a deep bond between the jewelry and its eventual owner."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Binliang Alexander Peng"])},
    "catchphrases": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crafting Your Personal Legacy in Fine Jewelry."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where Exceptional Craft Meets Your Unique Vision."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bespoke Elegance, Tailored for the Connoisseur."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exquisite Creations for the Discerning Individual."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beyond Luxury – A Journey into Personalized Artistry."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elevating Fine Jewelry to Personal Masterpieces."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Dreams, Handcrafted into Elegance."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclusive Artistry for the Extraordinary Individual."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defining Sophistication in Every Custom Piece."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where Your Imagination Becomes Exquisite Reality."])}
    ]
  }
}