<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 47.8033C37.2548 47.8033 48 37.1022 48 23.9016C48 10.7011 37.2548 0 24 0C10.7452 0 0 10.7011 0 23.9016C0 37.1022 10.7452 47.8033 24 47.8033Z" fill="white"/>
    <path d="M24 0C10.7457 0 0 10.7016 0 23.9016C0 34.0328 6.32099 42.6885 15.2494 46.1705C15.0321 44.282 14.8543 41.3705 15.3284 39.3049C15.763 37.4361 18.1333 27.423 18.1333 27.423C18.1333 27.423 17.4222 25.9869 17.4222 23.882C17.4222 20.5574 19.358 18.0787 21.7679 18.0787C23.8222 18.0787 24.8099 19.6131 24.8099 21.4426C24.8099 23.4885 23.5062 26.5574 22.8148 29.4098C22.242 31.7902 24.0198 33.7377 26.3704 33.7377C30.637 33.7377 33.916 29.2525 33.916 22.8C33.916 17.0754 29.7877 13.082 23.8815 13.082C17.0469 13.082 13.037 18.177 13.037 23.4492C13.037 25.4951 13.8272 27.6984 14.8148 28.8984C15.0123 29.1344 15.0321 29.3508 14.9728 29.5869C14.7951 30.3344 14.3802 31.9672 14.3012 32.3016C14.2025 32.7344 13.9457 32.8328 13.4914 32.6164C10.4889 31.2197 8.61235 26.8721 8.61235 23.3508C8.61235 15.8164 14.1037 8.8918 24.4741 8.8918C32.7901 8.8918 39.2691 14.7934 39.2691 22.7016C39.2691 30.9443 34.0543 37.5738 26.8247 37.5738C24.3951 37.5738 22.1037 36.3148 21.3333 34.8197C21.3333 34.8197 20.1284 39.3836 19.8321 40.5049C19.2988 42.5902 17.837 45.1869 16.8494 46.7803C19.1012 47.4689 21.4716 47.8426 23.9605 47.8426C37.2148 47.8426 47.9605 37.141 47.9605 23.941C48 10.7016 37.2543 0 24 0Z" fill="#E60019"/>
  </svg>
</template>

<script>
export default {
  name: "PinterestIcon"
}
</script>

<style scoped>

</style>