export default {
  "languagePref": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English ?"])},
    "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to see this website in English ?"])},
    "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])}
  },
  "cgv": {
    "iAcceptThe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'accepte les"])},
    "cgv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conditions générales de ventes"])},
    "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afin de continuer vous devez accepter les conditions générales de ventes."])}
  },
  "press": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ils parlent de nous ..."])}
  },
  "wedding": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bagues de Fiançailles et Alliances Sur Mesure"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réalisation des alliances et des bagues de fiançailles uniques en sur mesure."])},
    "headText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour moi chaque personne est unique et chaque union des deux êtres est un miracle de la vie alors les bijoux qui témoigneront de cet événement sans pareil se doivent d'être uniques également."])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez le joaillier"])},
    "howItWorks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment ça marche ?"])},
    "stepCall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appelons-nous"])},
    "stepCallExplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déterminez votre projet avec le joaillier."])},
    "stepAppointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premier rendez-vous"])},
    "stepAppointmentExplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous choisissons ensemble le style du bijou, la pierre dont nous allons utiliser, le joaillier réalise un ou plusieurs dessins suivant votre envie"])},
    "stepProto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prototype"])},
    "stepProtoExplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous présentons un prototype que vous validez, ensuite nous lançons la production à l'atelier"])},
    "stepShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison"])},
    "stepShippingExplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous présentons le bijou tant attendu dans son écrin sur Paris ou nous vous offrons l'envoi postal (assuré, contre signature)"])},
    "creationExample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemple de créations"])},
    "quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production artisanale de qualité"])},
    "qualityExplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication à la main à Paris avec des méthodes traditionnelles."])},
    "privileged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un traitement privilégié"])},
    "privilegedExpl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'êtes pas un numéro. Nous vous réservons un traitement spécial pour la réalisation de votre bijou."])},
    "precious": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matières précieuses certifiées"])},
    "preciousExpl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos fournisseurs d'or et d'argent sont certifiés par le \"Responsible Jewellery Council\"."])}
  },
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement... Merci de bien vouloir patienter."])},
  "progress": {
    "step1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Adresse"])},
    "step2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Livraison"])},
    "step3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Paiement"])}
  },
  "errorMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue, merci de réessayer plus tard."])},
  "eventsPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Événements"])},
    "subTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binliang Alexander Peng sera présent aux événements suivants :"])},
    "rev1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révélations se tient tous les deux ans à Paris."])},
    "rev2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes honorés d'avoir été sélectionnés par le Jury pour présenter nos créations."])},
    "rev3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'exposition est <strong>du 7 juin au 11 juin.</strong>"])},
    "buttonMoreInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site de l'exposition"])},
    "pastTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Événements passés"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
    "milanoTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milano Jewelry Week, 2022"])},
    "milano1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons participé à la Milano Jewelry Week."])},
    "milano2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'exposition a eu lieu au centre-ville du 20 au 23 octobre 2022."])},
    "chantalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exposition Galerie Psychée, 2022"])},
    "chantal1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons eu le plaisir d'exposer certaines de nos œuvres à la Galerie du 29 septembre au 3 octobre 2022."])},
    "chantal2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre de l'exposition : 'Dans le ventre d'un monstre'"])},
    "chantal3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La galerie est située 18 Rue du Pont Louis-Philippe, 75004 Paris"])},
    "chantalVideoCredit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédit vidéo : Galerie Psychée"])},
    "timelessTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeless Jewels, 2021"])},
    "timeless1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous étions heureux de pouvoir présenter notre travail à Timeless Jewels."])},
    "timeless2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'exposition a eu lieu en novembre 2021."])},
    "timeless3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons été accueillis dans le prestigieux 'Hôtel de l'industrie' à Saint-Germain des Près à Paris"])}
  },
  "invitationForm": {
    "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaque pièce produite par Binliang est unique et sa production est limitée."])},
    "line2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez partager vos coordonnées ci-dessous pour exprimer votre intérêt pour les créations exclusives de Binliang :"])}
  },
  "homeView": {
    "latestCreations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernières créations"])},
    "seeMoreCreations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir plus de créations"])},
    "eventsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Événements"])},
    "eventsExpl1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons été honorés d'être sélectionnés pour la dernière édition de <strong>Révélations</strong> !"])},
    "eventsExpl2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révélations est la Biennale Internationale des Métiers d'Art et de la Création."])},
    "eventsExpl3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrouvez nos dernières et prochaines expositions"])},
    "artisanJeweller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joaillier artisan"])},
    "altMainPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binliang Alexander Peng Bijoutier Joaillier parisien réalisant des pièces sur-mesure"])},
    "bespoke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur Mesure"])},
    "ourStore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créations"])},
    "wedding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mariage"])},
    "weddingExpl1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous concevons ensemble bagues de fiançailles et alliances."])},
    "weddingExpl2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un modèle unique et précieux, comme votre amour"])},
    "knowMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])},
    "ourCollections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos Collections"])},
    "ourCollectionsExpl1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous produisons toutes nos pièces dans notre atelier parisien."])},
    "ourCollectionsExpl2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos collections et pièces uniques sont en vente sur notre site."])},
    "seeCollections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir nos collections"])},
    "theBespoke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Sur-Mesure"])},
    "theBespokeExpl1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construisez un bijou d'exception fait à la main par un artisan d'art."])},
    "theBespokeExpl2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quoi de mieux qu'un bijou pour dire je t'aime à cette personne qui vous est si précieuse ?"])},
    "discoverHandmade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez nos pièces produites entièrement à la main"])},
    "conceiveUnique": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concevez un bijou unique."])},
    "bespokeArtisan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construisez votre bijou fait à la main par un artisan d'art."])},
    "beOriginal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyez original et ne ressemblez à personne d'autre."])},
    "artisanalProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une Production Artisanale"])},
    "madeInParis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labellisé Artisan D'art. Membre de la chambre des métiers de Paris. Toutes les pièces sont réalisées à la main par l'artisan joaillier dans son atelier parisien. "])},
    "atelier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atelier"])},
    "freeShip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison et retours gratuits"])},
    "freeShipDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La livraison standard et gratuite. Les retours sont égalements gratuits si le bijoux ne vous satisfait pas entièrement."])},
    "collections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collections"])},
    "surMesureJewels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des bijoux sur Mesure"])},
    "surMesureExpl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre atelier peut réaliser le bijou de vos rêves. Nous mettons à votre disposition notre atelier et notre équipe pour construire avec vous la pièce de vos rêves."])},
    "appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendez-vous"])}
  },
  "exclusivity": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations importantes"])},
    "expl1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les créations de Binliang sont proposées de façon exclusive."])},
    "expl2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En raison du caractère unique des pièces de Binliang, nous offrons la possibilité de les acquérir uniquement sur invitation."])},
    "expl3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si une œuvre vous captive et que vous souhaitez manifester votre intérêt, nous vous invitons à demander une invitation."])}
  },
  "jeweller": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon Histoire"])},
    "myInspiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon Inspiration"])}
  },
  "navbar": {
    "item": {
      "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Événements"])},
      "theJeweler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Joaillier"])},
      "collections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créations"])},
      "artisanal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production Artisanale"])},
      "surmesure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réalisation Sur Mesure"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
    }
  },
  "footer": {
    "copyright": {
      "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright 2022"])},
      "line2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous droits réservés"])}
    },
    "aboutus": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Maison"])},
      "companyHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le joaillier"])},
      "productionProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production artisanale"])},
      "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presse"])},
      "bespoke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur-Mesure"])}
    },
    "apropos": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A propos"])},
      "legalMentions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions Légales"])},
      "cgv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions générales de vente"])},
      "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La livraison"])},
      "retours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retours gratuits"])},
      "sitePlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan du site"])},
      "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement 3x sans frais"])}
    }
  },
  "pressView": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presse"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour toute demande d'information n'hésitez pas à nous écrire à l'addresse suivante : <a href=\"mailto:presse", "@", "binliang-alexander-peng.fr\">presse", "@", "binliang-alexander-peng.fr</a>."])}
  },
  "loadingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement en cours... Merci de patienter"])},
  "newsletter": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsletter:"])},
    "reassurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez en exclusivité nos dernières créations :"])}
  },
  "ratings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avis clients"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5/5 sur Google"])},
    "avis1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un joaillier à l'écoute et empathique (limite télépathe), très attaché à ciseler sur un bijou un pan de notre personnalité ou de notre passé. La bague me sied toujours avec les mois, que ce soit pour le métal ou la pierre, unique, personnel et à mon image. Incontournable!"])},
    "avis2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai confié à Binliang Alexander Peng la réalisation de ma première bague sur mesure. Binliang est un grand professionnel. Ses pierres sont superbes et les bijoux réalisés avec originalité et perfection. Très à l'écoute de ses clients, Binliang sait autant accompagner et rassurer ses clients que de les faire rêver. Je recommande à 100 % pour un bijou unique et qui vous représentera."])},
    "avis3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alexander a réalisé une bague sur mesure pour moi, un moment unique pour une pièce unique! Alexander a le don de raconter des histoires pour chacune de ses créations, leur donnant une dimension poétique magnifique. Ce fut pour moi une très belle expérience. Je vous le recommande chaleureusement!"])},
    "avis4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai découvert les créations de Binliang sur Insta et y suis tombée immédiatement amoureuse! Que de belles pierre, des modèles originaux et, en même temps, faciles à porter au quotidien. Et, surtout, vous ne risquerez pas en voir des pareils sur quelqu'un d'autre. D'autre part, j'ai eu la chance de rencontrer Binliang personnellement, de discuter avec lui et de choisir une de ces magnifiques bagues Gipsy. Pour moi, Binliang est la plus belle rencontre de l'année 2020 !"])},
    "avis5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au départ il y a une jolie aigue marine venant d'un bijou ancien, reçue en cadeau...\n            Ensuite, la découverte sur Instagram d'un artisan joaillier, Binliang.alexander.peng.\n            Son univers artistique m'a touché, j'aime la poésie, la créativité de ses bijoux... Alors j'ai fait appel à ses services afin d'avoir une bague unique.\n            Puis vient la rencontre de cet artiste, une belle personne, un jeune homme passionné par son métier, qui a envie de partager sa passion et son univers. J'ai eu la certitude de ne pas m'etre trompée, que j'aurai la bague qui me correspond....\n            Et enfin.... Je l'ai eu !\n            Une merveille !!!! 😍😍😍😍😍.\n            Encore Merci à l'artiste😉😍😍😍"])},
    "avis6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binliang est un artisan joaillier hors paire. Il est très professionnel et très doué dans la création de bijoux uniques et originaux. C’est une personne très à l’écoute des désirs et des besoins de ses clients. Il m’a réalisé un pendentif extraordinaire qui me ravi au plus au point. Je suis donc impatient de retravailler avec lui. Je vous le recommande les yeux fermés si vous souhaitez une création originale, unique et de très belle qualité. Encore mille mercis mon ami Binliang."])},
    "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Source: Google.com"])}
  },
  "product": {
    "badgeExclusive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièce unique, collection exclusive"])},
    "orderByPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commander par Téléphone"])},
    "alreadySold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce produit a déjà été vendu !"])},
    "threeTimesPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3x sans frais"])},
    "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter au panier"])},
    "inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande De Renseignements"])},
    "ctaPDP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous contacter"])},
    "inquiryFormTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande De Renseignements"])},
    "priceUponRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix Sur Demande"])},
    "requestPrivateViewing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essayage Privé"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrir"])},
    "availableForOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible sur commande"])},
    "contactUsToMake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous pour faire fabriquer ce bijou"])},
    "addToCartError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue, nous vous prions de bien vouloir nous excuser"])},
    "addToCartSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouté au panier !"])},
    "inquiryFirstParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette pièce a une histoire que je suis impatient de partager avec vous."])},
    "inquiryNameLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quel est votre nom ?"])},
    "inquirySend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyez Votre Demande"])},
    "inquiryPrivacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidentialité garantie : nous respectons et protégeons vos informations. Nous ne les partagerons jamais avec d'autres."])},
    "inquiryPhoneLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A quel numéro vous joindre ?"])}
  },
  "shipping": {
    "deliveryAtAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison à l'adresse:"])},
    "basketText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier"])},
    "address": {
      "updateLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'adresse"])}
    },
    "labelMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livriason à domicile :"])},
    "cartEmpty": {
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier et vide"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos collections"])}
    },
    "nextStepButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider la livraison"])},
    "preInvoice": {
      "totalArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total articles TTC"])},
      "totalShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total frais de ports TTC"])},
      "grandTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL TTC"])},
      "couponWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous disposez d'un code promo ? Vous pourrez l'ajouter à la prochaine étape."])}
    }
  },
  "address": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre Nom"])},
    "helpType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer à taper la première ligne de votre addresse :"])},
    "findYours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouver votre addresse"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de Livraison"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "complement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complément (facultatif)"])},
    "postCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code Postal"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "errorPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone non valide merci de corriger"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement..."])},
    "buttonValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livrer à cette addresse"])},
    "phoneCodeCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code pays"])},
    "phoneCountrySelectError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir un pays"])},
    "phoneLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
    "phoneExample": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exemple :"])},
    "phoneErrorValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro n'est pas valide"])},
    "fillManual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre addresse manuellement"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])}
  },
  "payment": {
    "wire": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement par virement"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyez votre virement"])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous ne traiterons votre commande que lorsque votre virement aura été reçu par notre banque."])},
      "ourRib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre RIB"])},
      "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banque :"])}
    },
    "selector": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choix du mode de paiement"])},
      "creditCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte bancaire"])},
      "paypal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paypal"])},
      "wire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virement Bancaire"])},
      "cheque": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chèque"])}
    }
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous contacter"])},
    "doubt1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous souhaitez nous poser une question ?"])},
    "doubt2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous hésitez entre plusieurs pièces ?"])},
    "doubt3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous souhaitez une réalisation sur mesure ? N'hésitez pas à nous contacter."])},
    "byChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par Chat"])},
    "openChat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir le Chat"])},
    "byPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par téléphone"])},
    "openingHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre atelier est ouvert <strong>tous les jours de 10h à 19h</strong>"])},
    "byEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par Email"])},
    "ourEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voici notre addresse email : support", "@", "binliang-alexander-peng.fr"])},
    "byForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Via notre formulaire de contact"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre message:"])},
    "messagePlaceHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quel est votre projet ? (optionnel)"])},
    "itsFree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le devis est gratuit !"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande a bien été enregistré "])},
    "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous vous contacterons dès que possible."])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attendant, n'hésitez pas à jeter un coup d'oeil à mes créations"])},
    "privateViewing": {
      "messageTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
      "messagePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionnel"])}
    },
    "inquiry": {
      "messagePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionnel"])}
    }
  },
  "bespoke": {
    "contactModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "contactModalClose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijoux Sur Mesure"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
    "artisanalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une Production 100% Artisanale"])},
    "artisanal1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les pièces sont réalisées à la main par l'artisan joaillier dans son atelier parisien."])},
    "artisanal2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membre de la chambre des métiers de Paris."])},
    "artisanal3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artisan d'art"])},
    "why": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourquoi faire appel à nous ?"])},
    "howItWorks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment ça marche ?"])}
  },
  "cart": {
    "removeFromSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer"])},
    "subTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-Total"])},
    "shipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison"])},
    "offered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offerte"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
    "unitPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix unitaire"])},
    "yourBasket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre Panier"])},
    "empty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier est actuellement vide"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeter un coup d'oeil à nos collections"])},
      "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos collections"])}
    },
    "toastSuccessUpdate": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succés !"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier a bien été mis à jour !"])}
    },
    "freeShipping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La livraison standard est offerte."])},
    "returnPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retours gratuits"])},
    "nextStepButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement"])},
    "paymentIsSecured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement sécurisé"])},
    "needHelp": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez besoin d'aide ?"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez un doute ? Vous souhaiter échanger avec le joaillier ?"])},
      "callButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+33 (0)1 23 45 67 89"])}
    },
    "itemsCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " pièce"]), _normalize([_interpolate(_named("count")), " pièces"])])}
  },
  "meta": {
    "addressCheckout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande: Addresse de livraison - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande: Addresse de livraison"])}
    },
    "artisanalPresentation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production Artisanale - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièces de joaillerie réalisées à la main. Cire perdue puis travail du métal, fabrication d'un moule, sertissage et polissage"])}
    },
    "events": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Événements - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prochain événement : Révélations 2023 à Paris au Grand Palais"])}
    },
    "cartV2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panier - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre Panier"])}
    },
    "CGV": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions générales de vente - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions générales de ventes de la boutique Binliang Alexander Peng"])}
    },
    "companyHistory": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histoire du joaillier - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binliang est un joaillier parisien découvrez son histoire"])}
    },
    "contactUs": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacter l'artisan joaillier pour réaliser un bijou sur mesure"])}
    },
    "homeView": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binliang Alexander Peng - Joaillier Artisan Parisien"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binliang Alexander Peng, Artisant Joaillier parisien de la Place Vendôme. Collections et réalisations sur mesure."])}
    },
    "legalMentions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions Légales - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binliang Alexander Peng, Mentions légales"])}
    },
    "mariageView": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bagues de fillançailles et aliances sur Mesure - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bagues de fillançailles et aliances sur Mesure, artisan joaillier parisien"])}
    },
    "orderSuccess": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande validée ! - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande validée"])}
    },
    "paymentCheque": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement par Chèque - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement par Chèque"])}
    },
    "paymentSelection": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choix de la méthode de paiement - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande - Choix de la méthode de paiement"])}
    },
    "paymentPaypal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande - Paiement par Paypal - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande - Paiement par Paypal"])}
    },
    "paymentStripe": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement sécurisé de votre commande - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande - Paiement par carte sécurisé"])}
    },
    "paymentWire": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement par Virement Bancaire - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande - Paiement par Virement Bancaire"])}
    },
    "pressView": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Presse - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur cette page notre contact Presse."])}
    },
    "returnConditions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions de retour - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retours gratuits pendant 30 jours après réception de la commande."])}
    },
    "shippingConditions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La livraison - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison standard gratuite."])}
    },
    "shippingChoose": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choix du mode de livraison - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choix du mode de livraison"])}
    },
    "storeMain": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boutique artisan joaillier - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijoux fait main dans notre atelier. Bagues, bracelets or et argent 18 carats"])}
    },
    "bespokePage": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création de Bijoux sur Mesure - Binliang Alexander Peng"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création de bijoux sur mesure par un artisan joaillier parisien"])}
    }
  },
  "cookieConsent": {
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'accepte"])},
    "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je refuse"])},
    "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masquer"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher"])},
    "partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les cookies"])},
    "partnerListTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des Cookies:"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies"])},
    "explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous utilisons des cookies pour améliorer votre expérience sur notre site. En continuant, vous acceptez notre utilisation des cookies"])},
    "metaDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous utilisons les cookies Meta pour un ciblage publicitaire amélioré et des analyses de site, améliorant votre expérience et garantissant un contenu et des publicités pertinents."])},
    "tiktok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous utilisons l'Event API Tiktok pour un ciblage publicitaire amélioré et des analyses de site, améliorant votre expérience et garantissant un contenu et des publicités pertinents."])},
    "mixpanelDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous utilisons Mixpanel pour mieux comprendre comment vous utiliser ce site web."])},
    "hotjarDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous utilisons Hotjar pour mieux comprendre comment vous utiliser ce site web."])},
    "googleDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous utilisons Google Tag Manager pour mieux comprendre comment vous utiliser ce site web afin de garantir un contenu et des publicités pertinents."])}
  },
  "onboarding": {
    "seeMyInstagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte Instagram"])},
    "latestCreations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelques Créations"])},
    "latestVideos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explication d'oeuvres"])},
    "seeMoreOnSocialInstaAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mon compte Instagram"])},
    "seeMoreOnSocial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez voir plus d'explications d'oeuvres sur"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez moi"])},
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bijoux Précieux Et Uniques"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je suis Binliang Alexander Peng, joaillier parisien. Dans mon atelier, chaque bijou est bien plus qu'un simple ornement ; il est le miroir de votre personnalité unique. Mon objectif ? Créer des pièces qui vont au-delà de la simple beauté, des créations qui racontent une histoire, la vôtre. Chaque œuvre est conçue pour être non seulement esthétiquement plaisante, mais également pour résonner avec votre individualité, transformant chaque bijou en une véritable expression artistique."])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce que 'Sur Mesure' Signifie Vraiment pour Moi "])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le sur-mesure, tel que je le conçois, est une aventure intime et profondément personnelle. C'est l'art de matérialiser vos visions en harmonie avec ma signature créative. Lorsque vos idées se mêlent à mon univers artistique, elles prennent vie sous la forme d'une œuvre qui vous est entièrement dédiée, irradiant de charme et d'authenticité."])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon Processus de Création :"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre expérience sur mesure avec moi s'initie par une communication privilégiée, que ce soit par téléphone, visioconférence ou lors d'une rencontre en personne. C'est un moment privilégié pour parler de vous, de vos aspirations, bien au-delà du simple concept de bijou. Ensemble, nous explorerons non seulement vos idées de design, mais aussi votre personnalité, vos passions, et ce qui vous rend unique. Si un écho se crée entre nos visions, je me consacrerai à transposer votre essence dans une création joaillière. C'est une aventure où l'artisanat traditionnel rencontre l'unicité de votre histoire, un voyage exclusif pour créer un bijou qui est non seulement un objet d'art, mais aussi un reflet fidèle de votre personnalité."])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Intime et Confidentialité :"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au sein de mon atelier, votre expérience et votre confidentialité sont sacrées. Je vous offre un accompagnement sur-mesure, veillant à ce que chaque pièce reflète fidèlement votre essence et votre parcours."])},
    "title5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir une Création :"])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir une pièce de mon atelier signifie investir dans quelque chose qui n'est pas seulement unique, mais aussi le reflet d'un esprit créatif partagé. Il s'agit de posséder un bijou imprégné de soin personnel et d'une touche artistique."])},
    "title6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expériences des Clients:"])},
    "title7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une Invitation à la Découverte Créative :"])},
    "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Célébrant l'exclusivité dans chaque création, je vous invite à partager votre inspiration avec moi. Cependant, veuillez noter qu'en tant qu'artiste qui valorise l'essence de la sélection, je choisis soigneusement les projets que j'entreprends. Ensemble, nous pouvons explorer si votre vision s'aligne avec moi, menant à la création d'une pièce vraiment unique et sur mesure."])},
    "title8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fait main uniquement dans le respect des traditions"])},
    "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaque pièce est façonnée à la main, une pratique qui souligne mon engagement envers l'artisanat authentique. Je choisis délibérément de ne pas utiliser de techniques de modélisation 3D, préférant la connexion intime et la précision que seul le travail manuel peut apporter. Ce processus garantit que chaque création est non seulement unique, mais également imprégnée d'une touche personnelle et d'un savoir-faire que seule la main de l'artiste peut offrir, établissant un lien profond entre le bijou et son futur propriétaire."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue dans mon atelier"])},
    "catchphrases": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Là où l'élégance intemporelle rencontre l'artisanat d'exception dans chaque création unique."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vivez une expérience de luxe inégalée avec des bijoux qui racontent votre histoire."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'art de la joaillerie fine se réinvente dans nos ateliers, unissant passion et perfection."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour ceux qui cherchent l'unique, découvrez des bijoux aussi extraordinaires que vos rêves les plus audacieux."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre signature : l'excellence artisanale pour une clientèle qui apprécie l'exclusivité."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au-delà de la brillance, un monde de raffinement et de savoir-faire inégalé."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exprimez votre style distinctif avec des pièces qui reflètent votre personnalité unique."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaque occasion devient mémorable avec un bijou façonné par des mains expertes."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'artisanat d'exception, pour transcender l'ordinaire et célébrer l'extraordinaire."])}
    ]
  }
}