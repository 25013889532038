<template>
  <div  class="container text-center mt-5">
    <div class="row">
      <div class="col-sm-12 col-md-4">
      </div>
      <div class="col-sm-12 col-md-4">
        <img class="img-fluid" :src="this.$cdnBaseUrl+'artisan-art.jpg'"  alt="Artisan d'art" style="width: 150px">
        <h2 class="bap-title mt-3" v-t="'homeView.artisanalProd'"></h2>
        <p v-t="'homeView.madeInParis'"></p>
        <router-link :to="{name:routeNameAtelier}">
          <button class="btn btn-custom-pink" >
            <span v-t="'homeView.atelier'"></span>
            <i class="bi bi-caret-right"></i>
          </button>
        </router-link>

      </div>
      <div class="col-sm-12 col-md-4">
      </div>
    </div>
  </div>
</template>

<script>
import RouteNames from "@/routeNames";
export default {
  name: "ReassuranceLine",
  components:{},
  data() {
    return {
      routeNameStore: RouteNames.STORE,
      routeNameType: RouteNames.SUR_MESURE,
      routeNameAtelier: RouteNames.ARTISANAL,
    }
  },
}
</script>

<style scoped>

</style>