<template>

  <div v-if="showLanguageModal" class="cookie-consent-overlay" style="z-index: 200">
    <div class="cookie-consent">
      <h2 v-t="'languagePref.title'"></h2>
      <p v-html="$t('languagePref.explanation')"></p>

      <button @click="declineLanguageChange" class="btn btn-secondary btn-custom" v-t="'languagePref.decline'"></button>
      <button @click="acceptLanguageChange" class="btn btn-success btn-custom btn-lg" v-t="'languagePref.accept'"></button>
    </div>
  </div>



  <div v-if="showConsent" class="cookie-consent-overlay">
    <div class="cookie-consent">
      <h2 v-t="'cookieConsent.title'"></h2>
      <p v-t="'cookieConsent.explanation'"></p>

      <button @click="togglePartnersList">
        {{ showPartners ?  $t('cookieConsent.hide')  : $t('cookieConsent.show') }} {{$t('cookieConsent.partners')}}
      </button>

      <div v-if="showPartners" class="partners-list">
        <h5 v-t="'cookieConsent.partnerListTitle'"></h5>
        <ul>
          <li v-for="partner in partners" :key="partner.name">
            <strong>{{ partner.name }}</strong>
            <p>{{ partner.description }}</p>
          </li>
        </ul>
      </div>

      <button @click="declineCookies" class="btn btn-secondary btn-custom" v-t="'cookieConsent.decline'"></button>
      <button @click="acceptCookies" class="btn btn-success btn-custom btn-lg" v-t="'cookieConsent.accept'"></button>


    </div>
  </div>
</template>

<script>

import Webservice from "@/webservice";
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import ScriptLoader from "@/scriptLoader";

export default {
  data() {
    return {
      showConsent: false,
      showPartners: false,
      showLanguageModal: false,
      hasLanguageDiff: false,
      partners: [
        { name: 'Mixpanel', description: this.$t('cookieConsent.mixpanelDesc') },
        //{ name: 'Hotjar', description: this.$t('cookieConsent.hotjarDesc') },
        { name: 'Meta', description: this.$t('cookieConsent.metaDesc') },
        { name: 'TikTok', description: this.$t('cookieConsent.tiktok') },
        { name: 'Google', description: this.$t('cookieConsent.googleDesc') },
        // Add more partners as needed
      ]
    };
  },
  mounted() {
    this.checkLanguageAndPrompt();
    this.checkCookieConsent();
    this.setUserCookieIfNotSet().then(()=>{
      this.loadHotjarIfNotLoaded();
    })
  },
  methods: {

    identifyHotjar(){
      let source = localStorage.getItem("utm_source")
      if (source){
        window.hj('event', 'start_paid_traffic');
        window.hj('identify', Cookies.get('u'), {
          'utm source': source
          // Add your own custom attributes here. Some EXAMPLES:
          // 'Signed up': '2019—06-20Z', // Signup date in ISO-8601 format.
          // 'Last purchase category': 'Electronics', // Send strings with quotes around them.
          // 'Total purchases': 15, // Send numbers without quotes.
          // 'Last purchase date': '2019-06-20Z', // Send dates in ISO-8601 format.
          // 'Last refund date': null, // Send null when no value exists for a user.
        })
      }
    },
    loadHotjarIfNotLoaded(){
      // if returning user that has already accepted the cookies
      if (Cookies.get('cookieConsent') === 'accepted') {
        ScriptLoader.loadGTM()
      }
      ScriptLoader.loadHotjar().then(()=>{
        this.identifyHotjar()
      }).catch((err)=>{
        console.log(err)
      })
    },
    setUserCookieIfNotSet(){
      return new Promise(function(resolve) {
        // Check if a user-specific cookie exists
        if (!Cookies.get('u')) {
          // Create a unique value for the user, e.g., a UUID or a timestamp
          const uniqueValue = uuidv4()
          // Set the cookie with the unique value
          Cookies.set('u', uniqueValue, {expires: 365});
          resolve()
        } else {
          resolve()
        }
      })
    },
    acceptCookies() {
      Cookies.set('cookieConsent', 'accepted', { expires: 365 });
      this.showConsent = false;
      // Logic for accepted cookies
      window.fbq('consent', 'grant');
      ScriptLoader.loadHotjar().then(()=>{
        this.identifyHotjar()
      })
      ScriptLoader.loadGTM()
      Webservice.trackAction("ACCEPT_COOKIES",this.$route)
    },
    declineCookies() {
      Cookies.set('cookieConsent', 'declined', { expires: 365 });
      this.showConsent = false;
      Webservice.trackAction("DECLINE_COOKIES",this.$route)
      window.fbq('consent', 'revoke');
      // Logic for declined cookies
    },
    checkCookieConsent() {
      if (!Cookies.get('cookieConsent')) {
        // no consent registered
        this.showConsent = true;
      }
    },
    togglePartnersList() {
      Webservice.trackAction("SHOW_COOKIE_PARTNERS",this.$route)
      this.showPartners = !this.showPartners;
    },
    checkLanguageAndPrompt() {
      const userLang = navigator.language || navigator.userLanguage;
      const currentLocale = process.env.VUE_APP_I18N_LOCALE
      const isFrench = userLang.startsWith('fr')
      //alert('userLang:'+userLang+' currentLocale:'+currentLocale+' isFrench:'+isFrench)
      if (currentLocale === 'fr' && !isFrench) {
        this.showLanguageModal = true;
      }
      if (currentLocale === 'en' && isFrench) {
        this.showLanguageModal = true;
      }
    },
    declineLanguageChange() {
      const currentLocale = process.env.VUE_APP_I18N_LOCALE
      const userLang = navigator.language || navigator.userLanguage;

      Webservice.trackAction("DECLINE_LANG_CHANGE",this.$route,{
        currentLocale:currentLocale,
        userLang:userLang
      })

      // Redirect to the English version
      this.showLanguageModal = false
    },
    acceptLanguageChange() {
      const currentLocale = process.env.VUE_APP_I18N_LOCALE
      const userLang = navigator.language || navigator.userLanguage;
      if (currentLocale === 'fr'){
        Webservice.trackAction("ACCEPT_LANG_CHANGE",this.$route,{
          currentLocale:currentLocale,
          userLang:userLang,
          redirectedTo:'.com'
        }).then(()=>{
          // go to en website
          let userId = ""
          if(Cookies.get('u')){
            userId = Cookies.get('u')
          }
          window.location.href = 'https://www.binliang-alexander-peng.com?u='+userId;
        })

      }
      if (currentLocale === 'en'){
        Webservice.trackAction("ACCEPT_LANG_CHANGE",this.$route,{
          currentLocale:currentLocale,
          userLang:userLang,
          redirectedTo:'.fr'
        }).then(()=>{
          let userId = ""
          if(Cookies.get('u')){
            userId = Cookies.get('u')
          }
          // go to ff website
          window.location.href = 'https://www.binliang-alexander-peng.fr?u='+userId;
        })

      }
    }
  }
}
</script>

<style>
.cookie-consent-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.cookie-consent {
  background-color: #fff;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
}

.partners-list {
  text-align: left;
  margin-top: 15px;
}

.btn-custom {
  margin-right: 10px; /* Adds space to the right of the button */
  padding: 10px 20px; /* Increases the size of the button */
  font-size: 1.2em; /* Makes the text inside the button larger */
}
</style>