<template>
  <div>
    <router-view/>
    <CookieConsent></CookieConsent>
  </div>
</template>

<script>
import ScriptLoader from "@/scriptLoader";
import CookieConsent from "@/components/CookieConsent.vue";

export default {
  components: {CookieConsent},
  created() {
    ScriptLoader.loadCrisp();

  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
