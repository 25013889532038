import axios from "axios";
import Cookie from "@/cookie/cookies";
import Cookies from "js-cookie";
import {v4 as uuidv4} from "uuid";


function getProductsV2Main(){
  return axios
      .get(process.env.VUE_APP_API_BASE_URL + '/v2/products?showMain=true',
          getDefaultHeader())
}

function getProductsV2MainHome(nb=3){
  return axios
      .get(process.env.VUE_APP_API_BASE_URL + '/v2/products/home?number='+nb,
          getDefaultHeader())
}

function getProductsV2ByID(productId){
  return axios
      .get(process.env.VUE_APP_API_BASE_URL + '/v2/products/'+productId,
          getDefaultHeader())
}

function addToCartV2(productId, delta, idToken){
  return axios.put(process.env.VUE_APP_API_BASE_URL+"/v2/me/cart",{
    productId : productId,
    delta: delta
  },{
    headers : {
      'Authorization': `Bearer ${idToken}`
    }
  })
}

function getMyCartV2(idToken){
  return axios.get(process.env.VUE_APP_API_BASE_URL+"/v2/me/cart",{
    headers : {
      'Authorization': `Bearer ${idToken}`
    }
  })
}

function getDefaultHeader(accessToken){
  return {headers: {
      'content-type': 'application/json',
      'Cache-Control': 'max-age=0',
      Authorization: `Bearer ${accessToken}`
    }}
}

function createAnonymousUser(){
  return axios.post(process.env.VUE_APP_API_BASE_URL+"/user/anonymous")
}

function updateCartAddress(idToken,address){
  return axios.put(process.env.VUE_APP_API_BASE_URL+"/v2/me/cart/address",address,{
    headers : {
      'Authorization': `Bearer ${idToken}`
    }
  })
}
function tracking(data, idToken = ""){
  if (localStorage.getItem("userStatus") === "admin"){
    data.d = true
  }
  return axios.post(process.env.VUE_APP_API_BASE_URL+"/v2/view",data,{
    headers : {
      'Authorization': `Bearer ${idToken}`
    }
  })
}

function trackAction(actionName, route, additionalData = {}){
  let userId = ""
  if(Cookies.get('u')){
    userId = Cookies.get('u')
  }
  let consent = ""
  if (Cookies.get('cookieConsent')){
    consent = Cookies.get('cookieConsent')
  }
  if (consent === "accepted" && userId === "") {
    // recreate userId if lost
    // Create a unique value for the user, e.g., a UUID or a timestamp
    const uniqueValue = uuidv4()
    // Set the cookie with the unique value
    Cookies.set('u', uniqueValue, { expires: 365 });
    userId = uniqueValue
  }

  let data = {
    action:{
      additionalData:additionalData,
      referrer:document.referrer,
      domain: window.location.hostname,
      lang: navigator.language || navigator.userLanguage,
      name:actionName,
      currentUrl : window.location.href,
      consent:consent,
      routeName: route.name,
      meta:{
        fbc:Cookie.getCookie('_fbc'),
        fbp:Cookie.getCookie('_fbp'),
        fbclid:localStorage.getItem('fbclid'),
        fbclid_time: localStorage.getItem('fbclid_time'),
      },
      tt:{
        ttclid:localStorage.getItem('ttclid')
      },
      userId : userId,
      productId:route.params.productId,
      utm: {
        source: route.query.utm_source,
        medium: route.query.utm_medium,
        campaign: route.query.utm_campaign,
        term: route.query.utm_term,
        content: route.query.utm_content,
      }
    }
  }
  if (localStorage.getItem("userStatus") === "admin"){
    data.d = true
  }
  let headers = {}


  return axios.post(process.env.VUE_APP_API_BASE_URL+"/v2/view",data,{
    headers : headers
  })
}

function getPaymentMethodsAvailable(accessToken,req){
  return axios
      .post(process.env.VUE_APP_API_BASE_URL + '/payment/methods',
          req,
          getDefaultHeader(accessToken))
}

function createPaymentIntent(accessToken,req) {
  return axios
      .post(process.env.VUE_APP_API_BASE_URL + '/payment/intent',
          req,
          getDefaultHeader(accessToken))
}

function paymentFrontApproval(accessToken){
  return axios
      .post(process.env.VUE_APP_API_BASE_URL + '/payment/onFrontApproval',
          null,
          getDefaultHeader(accessToken))
}

function createContactV2(data){
  return axios
      .post(process.env.VUE_APP_API_BASE_URL + '/contact/v2',
          data,
          getDefaultHeader())
}


function triggerChat(req) {
  return axios
      .post(process.env.VUE_APP_API_BASE_URL + '/trigger/chat',
          req,
          getDefaultHeader())
}

export default {
  getProductsV2Main:getProductsV2Main,
  getProductsV2MainHome:getProductsV2MainHome,

  getProductsV2ByID:getProductsV2ByID,
  addToCartV2:addToCartV2,
  getMyCartV2:getMyCartV2,
  createAnonymousUser:createAnonymousUser,
  updateCartAddress:updateCartAddress,
  tracking:tracking,
  getPaymentMethodsAvailable:getPaymentMethodsAvailable,
  createPaymentIntent:createPaymentIntent,
  paymentFrontApproval:paymentFrontApproval,
  createContactV2:createContactV2,
  triggerChat:triggerChat,
  trackAction:trackAction
}
