export default {
  HOME: "HOME",
  CART: "CART",
  MARIAGE: "MARIAGE",
  PRODUCT_DETAIL_V2: "PRODUCT_DETAIL_V2",
  ATELIER:"ATELIER",
  SUR_MESURE:"SUR_MESURE",
  THE_JEWELLER:"THE_JEWELLER",
  ARTISANAL:"ARTISANAL",
  PRESSE:"PRESSE",
  LEGAL_MENTIONS:"LEGAL_MENTIONS",
  CGV:"CGV",
  SHIPPING_CONDITIONS:"SHIPPING_CONDITIONS",
  RETOURS:"RETOURS",
  PAYMENT_CONDITIONS:"PAYMENT_CONDITIONS",
  CONTACT_US:"CONTACT_US",
  STORE:"STORE",
  ADDRESS_CHECKOUT:"ADDRESS_CHECKOUT",
  SHIPPING:"SHIPPING",
  PAYMENT_METHOD_SELECTION:"PAYMENT_METHOD_SELECTION",
  PAYMENT_STRIPE:"PAYMENT_STRIPE",
  PAYMENT_CHEQUE:"PAYMENT_CHEQUE",
  PAYMENT_PAYPAL:"PAYMENT_PAYPAL",
  PAYMENT_WIRE : "PAYMENT_WIRE",
  SUCCESS : "SUCCESS",

  // Content
  ALLIANCES_SUR_MESURE_PARIS:"ALLIANCES_SUR_MESURE_PARIS",
  NOT_FOUND:"NOT_FOUND",
  EVENTS:"EVENTS",
  LP1 : "LP1",
  RDV_CONFIRME : "RDV_CONFIRME"

}
