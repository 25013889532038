<template>
  <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" enable-background="new 0 0 48 48">
    <rect x="16" y="15" fill="#BF360C" width="16" height="18"/>
    <path fill="#78909C" d="M40,44H8c0-11,11-13,11-13h10C29,31,40,33,40,44z"/>
    <path fill="#FF9800" d="M24,37c-2.2,0-5-6-5-6v-6h10v6C29,31,26.2,37,24,37z"/>
    <path fill="#FFB74D" d="M33,14c0-7.6-18-5-18,0c0,1.1,0,5.9,0,7c0,5,4,9,9,9s9-4,9-9C33,19.9,33,15.1,33,14z"/>
    <path fill="#FF5722" d="M24,4C17.9,4,9,7.4,9,27.3l7,4.7V19l12-7l4,5v15l7-6c0-4-0.7-20-11-20l-1-2H24z"/>
    <path fill="#FFB74D" d="M24,38c-4.4,0-5-7-5-7s2.5,4,5,4s5-4,5-4S28.4,38,24,38z"/>
    <circle fill="#784719" cx="28" cy="21" r="1"/>
    <circle fill="#784719" cx="20" cy="21" r="1"/>
  </svg>
</template>

<script>
export default {
  name: "BusinessWomanIcon"
}
</script>

<style scoped>

</style>