
function loadPaypal() {
    return new Promise(function(resolve, reject){
        let src = "https://www.paypal.com/sdk/js?currency="+process.env.VUE_APP_PAYPAL_CURRENCY+"&client-id="+process.env.VUE_APP_PAYPAL_CLIENT_ID;
        let shouldAppend = false;
        let el = document.querySelector('script[src="' + src + '"]');
        if (!el) {
            el = document.createElement('script');
            el.type = 'text/javascript';
            //el["data-order-id"] = orderId
            //el["data-page-type"] = "checkout"
            el.async = true;
            el.src = src;
            shouldAppend = true;
        }
        else if (el.hasAttribute('data-loaded')) {
            resolve(el);
            return;
        }
        el.addEventListener('error', reject);
        el.addEventListener('abort', reject);
        el.addEventListener('load', function loadScriptHandler() {
            el.setAttribute('data-loaded', true);
            resolve(el);
        });
        if (shouldAppend) document.head.appendChild(el);
    })
}

function loadHotjar(){
    // Check if the script is already appended
    return new Promise(function(resolve) {
        let src = "https://static.hotjar.com/c/hotjar-" + process.env.VUE_APP_HOTJAR_ID + ".js?sv=6"
        let el = document.querySelector('script[src="' + src + '"]');
        if (!el) {
            let script = document.createElement('script');

            // Set the script type to JavaScript (optional, as it's the default)
            script.type = 'text/javascript';

            // Set the async attribute to true
            script.async = true;


            // Define the Hotjar script content inside the script element
            script.textContent = `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:` + process.env.VUE_APP_HOTJAR_ID + `,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

            // Append the script element to the <head> of the document
            document.head.appendChild(script);
            resolve()
        } else {
            resolve()
        }
    });

}

function loadCrisp() {
    return new Promise(function(resolve, reject){
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = process.env.VUE_APP_CRISP_WEBSITE_ID;
        let src = "https://client.crisp.chat/l.js";
        let shouldAppend = false;
        let el = document.querySelector('script[src="' + src + '"]');
        if (!el) {
            el = document.createElement('script');
            el.type = 'text/javascript';
            el.async = true;
            el.src = src;
            shouldAppend = true;
        }
        else if (el.hasAttribute('data-loaded')) {
            resolve(el);
            return;
        }
        el.addEventListener('error', reject);
        el.addEventListener('abort', reject);
        el.addEventListener('load', function loadScriptHandler() {
            el.setAttribute('data-loaded', true);
            resolve(el);
        });
        if (shouldAppend) document.head.appendChild(el);
    })
}

function loadGTM(){
    // Check if the script is already appended
    let src = 'https://gtm2.binliang-alexander-peng.fr/gtm.js?id=' + process.env.VUE_APP_GTM_ID;

    let el = document.querySelector('script[src="' + src + '"]');
    if (!el) {
        console.log("not loaded GTM")
        let script = document.createElement('script');

        // Set the script type to JavaScript (optional, as it's the default)
        script.type = 'text/javascript';

        // Set the async attribute to true
        script.async = true;


        // Define the Hotjar script content inside the script element
        script.textContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://gtm2.binliang-alexander-peng.fr/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','`+process.env.VUE_APP_GTM_ID+`');`;

        // Append the script element to the <head> of the document
        document.head.appendChild(script);
    } else {
        console.log("loaded GTM")
    }
}

export default {
    loadPaypal:loadPaypal,
    loadCrisp:loadCrisp,
    loadHotjar:loadHotjar,
    loadGTM:loadGTM,
}