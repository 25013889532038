<template>
  <div>
    <div  class="row mb-3">
      <div class="col">
        <h1 v-if="displayTitle" class="bap-title-darker" v-t="'ratings.title'"></h1>
        <p v-t="'ratings.summary'"></p>
      </div>
    </div>
    <div class="row">
      <div class="col col-md-6 col-sm-12">
        <blockquote>
          <img v-if="showPhotos"  class="float-left p-3" src="https://cdn.binliang-alexander-peng.fr/photo-margo-c.png"  alt="Circle image">
          <p v-t="'ratings.avis1'"></p>
          <footer class="blockquote-footer">
            Margo C.
          </footer>
        </blockquote>
      </div>
      <div class="col col-md-6 col-sm-12">
        <blockquote>
          <business-woman-icon  v-if="showPhotos" style="width: 80px;" class="float-left"></business-woman-icon>
          <p v-t="'ratings.avis2'"></p>
          <footer class="blockquote-footer">
            Nathalie P.
          </footer>
        </blockquote>
      </div>
    </div>
    <div class="row">
      <div class="col col-md-6 col-sm-12">
        <blockquote>
          <business-woman-icon v-if="showPhotos" style="width: 80px;" class="float-left"></business-woman-icon>
          <p v-t="'ratings.avis3'"></p>
          <footer class="blockquote-footer">
            Valentine P.
          </footer>
        </blockquote>
      </div>
      <div class="col col-md-6 col-sm-12">
        <blockquote>
          <business-woman-icon v-if="showPhotos" style="width: 80px;" class="float-left"></business-woman-icon>
          <p v-t="'ratings.avis4'"></p>
          <footer class="blockquote-footer">
            Svetlana B.
          </footer>
        </blockquote>
      </div>
    </div>
    <div class="row">
      <div class="col col-md-6 col-sm-12">
        <blockquote>
          <business-woman-icon v-if="showPhotos" style="width: 80px;" class="float-left"></business-woman-icon>
          <p v-t="'ratings.avis5'" ></p>
          <footer class="blockquote-footer">
            Christine P.
          </footer>
        </blockquote>
      </div>
      <div class="col col-md-6 col-sm-12">
        <blockquote>
          <MaleClientIcon v-if="showPhotos" style="width: 80px;" class="float-left mr-1"></MaleClientIcon>
          <p v-t="'ratings.avis6'"></p>
          <footer class="blockquote-footer">
            Arnaud C.
          </footer>
        </blockquote>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <p class="small" v-t="'ratings.source'"></p>
      </div>
    </div>
  </div>
</template>

<script>
import BusinessWomanIcon from "@/components/icons/BusinessWomanIcon";
import MaleClientIcon from "@/components/icons/MaleClientIcon";
export default {
  name: "RatingElement",
  components: {MaleClientIcon, BusinessWomanIcon},
  props:{
    displayTitle: Boolean,
    showPhotos:Boolean,
  },
}
</script>


<style scoped>
</style>
