<template>
  <div>
    <h2 class="bap-title" v-t="'newsletter.title'">Newsletter:</h2>
    <p v-t="'newsletter.reassurance'"></p>
    <label class="sr-only" >Email:</label>
    <div class="row">
      <div class="col-md-6">
        <form @submit="onSubmit">
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">@</span>
            <input v-model="email" class="form-control" placeholder="mon@email.com" type="email" aria-label="email" aria-describedby="basic-addon1">
            <button class="btn btn-outline-secondary" type="button" id="button-addon2"><i class="bi bi-check2-circle"></i></button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import WebService from "@/webservice";

export default {
  name: "NewsletterInput",
  components: {},
  data() {
    return {
      email: "",
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault()
      let req = {
        email: this.email
      }
      WebService.saveSubscriber(req).then(()=>{
        this.makeToast("C'est noté !", "Merci pour votre abonnement", "success")
        this.email = ""
      }).catch(()=>{
        this.makeToast("Erreur", "Une erreur est survenue merci de réessayer plus tard", "error")
      })

    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: 5000,
        appendToast: true
      })
    },
  }
}
</script>

<style scoped>

</style>
