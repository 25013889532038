<template>
  <div>
    <div v-if="locale === 'fr'">
      <a @click="onClickCTA('en')"  class="link-primary">EN</a> |
      FR
    </div>
    <div v-if="locale === 'en'">
      EN |
      <a @click="onClickCTA('fr')"  class="link-primary">FR</a>
    </div>
  </div>
</template>

<script>
import Webservice from "@/webservice";

export default {
  name: "LanguageSwitch",
  data() {
    return {
      locale : process.env.VUE_APP_I18N_LOCALE,
    }
  },
  methods:{
    onClickCTA(cta){
      Webservice.trackAction("SWITCH_LANG_"+cta,this.$route).then(()=>{
        if(cta === 'fr'){
          window.location.href = "https://www.binliang-alexander-peng.fr";
        }
        if(cta === 'en'){
          window.location.href = "https://www.binliang-alexander-peng.com";
        }
      })
    }
  }

}
</script>

<style scoped>

</style>