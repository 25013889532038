<template>
  <div class="container text-center">
    <div class="row">
      <div class="col-12 col-md-4" v-for="(product,idx) in products" :key="idx">
        <router-link
            :to="{ name: routeNamePDP, params: { slug: product.slug[$i18n.locale],productId: product.id }}" class="text-decoration-none">

          <div class="card mb-2" v-if="product.images !== null && product.images.length > 0">
            <img :src="product.images[0].url" class="card-img-top img-fluid" :alt="product.images[0].alt[$i18n.locale]">
            <div class="card-body">
              <h5 class="card-title bap-pink">{{product.title[$i18n.locale]}}</h5>

              <button v-t="'product.view'"  class="btn btn-outline-secondary " id="tooltip-add-to-cart"></button>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import RouteNames from "@/routeNames";

export default {
  name: "ProductListV2",
  props: {
    products: Array
  },
  data() {
    return {
      routeNamePDP : RouteNames.PRODUCT_DETAIL_V2,
      meta : null,
      loading : false,
      lang : this.$i18n.locale,
    }
  },
}
</script>

<style scoped>

</style>
