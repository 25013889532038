<template>
  <div>
    <metainfo></metainfo>
    <Navbar></Navbar>
    <!-- DESKTOP -->
    <div  class="container desktop-version">
      <div  class="row mb-2 background-img">

        <div class="col over-image" >
          <h1 class="bap-title" style="color: white">{{ $t('homeView.artisanJeweller') }}<br> Paris</h1>
          <p>
            <router-link :to="{name:routeNameType}">
              <button  size="lg" class="btn pt-8 pb-8 mb-2 btn-custom-pink" >
                <span v-t="'homeView.bespoke'"></span>
                <i class="bi bi-caret-right"></i>
              </button>
            </router-link>
          </p>
          <router-link :to="{name:routeNameStore}">
            <button  size="lg" class="btn pt-8 pb-8 btn-custom-pink"  >
              <span v-t="'homeView.ourStore'"></span>
              <i class="bi bi-caret-right"></i>
            </button>
          </router-link>
        </div>
        <div class="col-9">
        </div>
      </div>
      <!-- List of products -->
      <DefaultLoader  v-if="loading"></DefaultLoader >
      <div class="row" v-else>
        <h1 class="bap-title-darker" v-t="'homeView.latestCreations'"></h1>

        <ProductListV2 :products="this.products"></ProductListV2>
      </div>
      <router-link  :to="{name:routeNameStore}">
        <div class="row mb-5">
            <button  size="lg" class="btn pt-8 pb-8 btn-custom-pink"  >
              <span v-t="'homeView.seeMoreCreations'"></span>
              <i class="bi bi-caret-right"></i>
            </button>
        </div>
      </router-link>
      <hr>

      <div class="row">
        <div class="col">
          <h1 class="bap-title-darker" v-t="'homeView.eventsTitle'"></h1>
          <br>
          <p v-html="$t('homeView.eventsExpl1')"></p>
          <p v-html="$t('homeView.eventsExpl2')"></p>
          <p v-html="$t('homeView.eventsExpl3')"></p>
          <p>
            <router-link :to="{name:routeNameEvents}">
              <button size="lg" id="btn-order-gift-desktop" class="btn pt-8 pb-8 mb-2 mt-3 btn-custom-pink"  >
                <span v-t="'homeView.knowMore'"></span>
                <i class="bi bi-caret-right"></i>
              </button>
            </router-link>
          </p>
        </div>

        <div  class="col pt-4">
          <img alt="revelations-2023" src='@/assets/img_revelations.jpg' class="img-small-home img-fluid" >
        </div>
      </div>

      <hr>
      <div class="row">
        <div class="col">
          <router-link class=""
                       :to="{name:routeNameStore}">
            <img alt="la vie en rose"  :src="this.$cdnBaseUrl+'la_vie_en_rose_new_3-bis.jpg'" class="img-fluid img-small-home">
          </router-link>
        </div>
        <div  class="col pt-4">
          <h1 class="bap-title-darker" v-t="'homeView.ourCollections'"></h1>
          <br>
          <p v-t="'homeView.ourCollectionsExpl1'"></p>
          <p v-t="'homeView.ourCollectionsExpl2'"></p>
          <p></p>
          <br>
          <p>
            <router-link :to="{name:routeNameStore}">
              <button  size="lg" class="btn pt-8 pb-8 mb-2 mt-3 btn-custom-pink" >
                <span  v-t="'homeView.seeCollections'"></span>
                <i class="bi bi-caret-right"></i>
              </button>
            </router-link>

          </p>
        </div>
      </div>
      <hr>
      <div class="row">
        <div  class="col pt-4">
          <h1 class="bap-title-darker">{{ $t('homeView.theBespoke') }}</h1>
          <br>
          <p v-t="'homeView.theBespokeExpl1'"></p>
          <p v-t="'homeView.theBespokeExpl2'"></p>
          <p>
            <router-link :to="{name:routeNameType}">
              <button  size="lg" class="btn pt-8 pb-8 mb-2 mt-3 btn-custom-pink" id="btn-surmesure-desktop"  >
                <span v-t="'homeView.bespoke'"></span>
                <i class="bi bi-caret-right"></i>
              </button>
            </router-link>

          </p>
        </div>
        <div class="col">
          <router-link class="" :to="{name:routeNameMariage}">
            <img alt=" " :src="this.$cdnBaseUrl+'IMG_8285.jpg'" class="img-small-home img-fluid">
          </router-link>
        </div>
      </div>
    </div>
    <!-- END DESKTOP -->

    <!-- MOBILE -->
    <div  class="container mobile-version">
      <div  class="row mb-2">
        <div class="col">
          <router-link class=""
                       :to="{name:routeNameStore}">
            <img alt="la vie en rose" class="img-fluid" :src="this.$cdnBaseUrl+'la_vie_en_rose_new_3-bis.jpg'" >
          </router-link>
        </div>
      </div>
      <div  class="row text-center">
        <div class="col">
          <!-- first block -->
          <h1 class="bap-title-darker">{{ $t("homeView.artisanJeweller") }}</h1>
          <h2 class="bap-title">10 Place Vendôme, Paris</h2>
          <p v-t="'homeView.discoverHandmade'"></p>
          <router-link  :to="{name:routeNameStore}">
            <button  size="lg" class="btn pt-8 pb-8 mb-4 btn-custom-pink">
              <span v-t="'homeView.ourCollections'"></span>
              <i class="bi bi-caret-right"></i>
            </button>
          </router-link>

          <!-- List of products -->
          <DefaultLoader  v-if="loading"></DefaultLoader >
          <div class="row" v-else>
            <h1 class="bap-title-darker" v-t="'homeView.latestCreations'"></h1>

            <ProductListV2 :products="this.products"></ProductListV2>
          </div>
          <router-link  :to="{name:routeNameStore}">
            <div class="row mb-5">
                <button  size="lg" class="btn pt-8 pb-8 btn-custom-pink"  >
                  <span v-t="'homeView.seeMoreCreations'"></span>
                  <i class="bi bi-caret-right"></i>
                </button>
            </div>
          </router-link>
          <hr>

          <img alt="revelations-2023" src='@/assets/img_revelations.jpg' class="img-small-home img-fluid mb-2" >
          <h1 class="bap-title-darker" v-t="'homeView.eventsTitle'"></h1>
          <br>
          <p v-html="$t('homeView.eventsExpl1')"></p>
          <p v-html="$t('homeView.eventsExpl2')"></p>
          <p v-html="$t('homeView.eventsExpl3')"></p>

          <router-link :to="{name:routeNameEvents}">
            <button size="lg" id="btn-order-gift-desktop" class="btn pt-8 pb-8 mb-2 mt-3 btn-custom-pink"  >
              <span v-t="'homeView.knowMore'"></span>
              <i class="bi bi-caret-right"></i>
            </button>
          </router-link>

          <router-link class="" :to="{name:routeNameMariage}">
            <img  :src="this.$cdnBaseUrl+'mariage.jpg'" class="img-small-home mb-2 img-fluid" >
          </router-link>
          <h2 class="bap-title" v-t="'homeView.wedding'"></h2>
          <p v-t="'homeView.conceiveUnique'"></p>
          <router-link :to="{name:routeNameMariage}">
            <button  size="lg" class="btn pt-8 pb-8 mb-4 btn-custom-pink" v-t="'homeView.knowMore'" >
              En Savoir Plus
              <i class="bi bi-caret-right"></i>
            </button>
          </router-link>

          <router-link class="" :to="{name:routeNameType}">
            <img  :src="this.$cdnBaseUrl+'bague-royaline.jpg'" class=" img-small-home img-fluid" >
          </router-link>
          <h2 class="bap-title mt-2" v-t="'homeView.theBespoke'"></h2>
          <p v-t="'homeView.bespokeArtisan'"></p>
          <p v-t="'homeView.beOriginal'"></p>

          <router-link :to="{name:routeNameType}">
            <button  size="lg" class="btn pt-8 pb-8 mb-4 btn-custom-pink" v-t="'homeView.bespoke'">
              <i class="bi bi-caret-right"></i>
            </button>
          </router-link>

          <hr>

        </div>
      </div>
    </div>
    <!-- END MOBILE -->
    <ReassuranceLine></ReassuranceLine>
    <div class="container">
      <hr>
      <NewsletterInput></NewsletterInput>
    </div>
    <div class="container">
      <hr>
      <RatingElement :display-title="true"></RatingElement>
    </div>

    <TalkAboutUs :display-title="true" :is-link-active="true" :has-beginning-hr="true" :cdn-base-url="this.$cdnBaseUrl"></TalkAboutUs>
    <FooterElement></FooterElement>
  </div>
</template>

<script>
import FooterElement from "@/components/FooterElement";
import Navbar from "@/components/NavBar";
import RouteNames from "@/routeNames";
import TalkAboutUs from "@/components/TalkAboutUs";
import NewsletterInput from "@/components/NewsletterInput";
import RatingElement from "@/components/RatingElement";
import ReassuranceLine from "@/components/ReassuranceLine";
import WebService from "@/webservice";
import DefaultLoader from "@/components/DefaultLoader.vue";
import ProductListV2 from "@/components/ProductListV2.vue";

export default {
  name: "HomeView",
  components: {
    ProductListV2,
    DefaultLoader,
    ReassuranceLine,
    RatingElement, NewsletterInput, TalkAboutUs, Navbar, FooterElement},
  created() {
    this.fetchData()
  },
  metaInfo(){
    const title = this.$t('meta.homeView.title');
    const description = this.$t('meta.homeView.description');
    return {
      title: title,
      htmlAttrs: {
        lang: process.env.VUE_APP_I18N_LOCALE,
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: this.$siteName },
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]
    }
  },
  data() {
    return {
      loading:false,
      products:[],
      routeNameMariage: RouteNames.MARIAGE,
      routeNameStore: RouteNames.STORE,
      routeNameType: RouteNames.SUR_MESURE,
      routeNameAtelier: RouteNames.ARTISANAL,
      routeNameEvents: RouteNames.EVENTS,

    }
  },
  methods:{
    fetchData() {
      this.loading = true
      WebService.getProductsV2MainHome().then((res)=>{
        this.products = res.data
        this.loading = false
      }).catch((err)=>{
        this.loading = false
        console.log("error while get current user"+err)
      })
    },
  }
}
</script>

<style scoped>
.btn-huge {
  padding-top: 20px;
  padding-bottom: 20px;
}

.carousel-caption {
  right: 5% !important;
  bottom: 100px !important;
  left: 5% !important;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
.background-img {
  background-image: url(https://cdn.binliang-alexander-peng.fr/home-1-binliang-alexander-peng.jpg);
  height:500px;
}
.over-image{
  margin-top: 180px;
  margin-left: 100px;
}
</style>
