import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useScrollStore = defineStore('scrollParams', () => {
    const scrollTop = ref(0)
    function changeScrollTop(newValue) {
        scrollTop.value = newValue
    }
    const getScrollTop = computed(() => scrollTop.value)


    return {
        changeScrollTop,
        getScrollTop,
    }
})
