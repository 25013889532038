<template>
  <div class="container">
    <hr v-if="hasBeginningHr">
    <h5 v-if="displayTitle" v-t="'press.title'" class="bap-title"></h5>
    <div  class="row text-center">
      <div  class="col mt-5">
        <a @click="handleClick" :class="{ 'no-pointer': !isLinkActive }" href="https://www.vogue.co.uk/fashion/article/jewellery-trends-2023" target="_blank">
          <img alt="british vogue" src='@/assets/british-vogue.jpg' class="img-opacity" >
        </a>
      </div>
      <div  class="col mt-5">
          <img  src='@/assets/numero.jpg' class="img-opacity" alt="logo Numero">
      </div>
      <div class="col mt-5">
        <a @click="handleClick" :class="{ 'no-pointer': !isLinkActive }" href="https://madame.lefigaro.fr/bijoux-et-montres/le-salon-bijoux-timeless-jewels-a-paris-vintage-contemporain-031121-198956" target="_blank">
          <img   :src="cdnBaseUrl+'Madame_Figaro-svg.svg'"  class=" img-opacity mt-4" alt="logo madame figaro">
        </a>
      </div>
      <div  class="col mt-5">
        <a @click="handleClick" :class="{ 'no-pointer': !isLinkActive }" href="https://www.moncarnet-gala.fr/articles/view/BINLIANG-ALEXANDER-PENG" target="_blank">
          <img  :src="cdnBaseUrl+'logo_gala.png'" class="img-opacity" alt="logo Gala">
        </a>
      </div>
      <div class="col">
        <a @click="handleClick" :class="{ 'no-pointer': !isLinkActive }" href="https://www.youtube.com/watch?v=TNTspxeeW3s&ab_channel=HauteEcoledeJoaillerie" target="_blank">
          <img  :src="cdnBaseUrl+'logo_hej.jpg'" class="img-opacity img-fluid" alt="logo haute école de joaillerie">
        </a>
      </div>

    </div>
    <div  class="row text-center">
      <div class="col">
        <a @click="handleClick" :class="{ 'no-pointer': !isLinkActive }" href="https://www.revelations-grandpalais.com/exposant/binliang-alexander-peng/" target="_blank">
          <img alt="revelations-2023" src='@/assets/revelations.png' style="width: 300px;" class="img-opacity" >
        </a>

      </div>
      <div class="col">
        <a @click="handleClick" :class="{ 'no-pointer': !isLinkActive }" href="https://www.naturaldiamonds.com/fr/style-innovation/coups-de-coeur-pour-les-createurs/" target="_blank">
          <img  :src="cdnBaseUrl+'only_natural_diamonds.webp'"  class=" img-opacity" alt="logo only natural diamonds">
        </a>
      </div>
      <div class="col">
        <a @click="handleClick" :class="{ 'no-pointer': !isLinkActive }" href="https://www.instagram.com/p/CY3kwZRoAlo/?hl=fr" target="_blank">
          <img  :src="cdnBaseUrl+'logo-ubh.png'"  class=" img-opacity" alt="logo UBH">
        </a>
      </div>
      <div class="col">
        <a @click="handleClick" :class="{ 'no-pointer': !isLinkActive }" href="https://www.instagram.com/p/CbbS3RwomDZ/?hl=fr" target="_blank">
          <img  :src="cdnBaseUrl+'dreams.png'"  class=" img-opacity" alt="logo dreams magazine">
        </a>
      </div>
      <div class="col">
        <a @click="handleClick" :class="{ 'no-pointer': !isLinkActive }" href="https://espritjoaillerie.com/2022/02/22/rencontre-avec-binliang-alexander-peng/" target="_blank">
          <img  :src="cdnBaseUrl+'esprit_joaillerie_logo.webp'"  class=" img-opacity" alt="logo esprit joaillerie">
        </a>
      </div>

    </div>
  </div>
</template>

<script>
export default {
name: "TalkAboutUs",
  components: {},
  props: {
    cdnBaseUrl: String,
    hasBeginningHr : Boolean,
    displayTitle: Boolean,
    isLinkActive: Boolean,

  },
  methods: {
    handleClick(event) {
      if (!this.isLinkActive) {
        event.preventDefault();
        // Optionally, do something else here if the link is disabled
      }
    }
  },
  data() {
    return {
    };
  },
}
</script>

<style scoped>
.img-opacity{
  opacity: 0.6;
  width: 170px;
}

.no-pointer {
  cursor: default; /* or 'none' to hide the cursor */
}
</style>
