<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.4706 37.3231C15.4211 37.8246 16.4797 38.0864 17.5543 38.086C21.1338 38.086 24.0559 35.2387 24.1878 31.69L24.201 0H32.1076C32.1083 0.673085 32.1709 1.34468 32.2945 2.00632H26.5053V2.00742H34.4129C34.4118 4.65963 35.3731 7.22216 37.1185 9.21918L37.1207 9.22173C38.9011 10.3848 40.9821 11.0032 43.1088 11.0012V12.7622C43.8531 12.9216 44.6227 13.0065 45.4142 13.0065V20.9152C41.4667 20.9198 37.6179 19.6821 34.413 17.3775V33.4468C34.413 41.4709 27.8839 48 19.8586 48C17.8601 48.0005 15.883 47.5882 14.0512 46.7889C12.2207 45.9903 10.5749 44.8224 9.21653 43.3584L9.21355 43.3563C5.46035 40.7212 3 36.3633 3 31.4404C3 23.4151 9.52906 16.885 17.5543 16.885C18.2106 16.8881 18.8658 16.9359 19.5156 17.0279V18.9001C19.5613 18.8992 19.6067 18.8976 19.6523 18.8961C19.7207 18.8937 19.7893 18.8913 19.8586 18.8913C20.5148 18.8944 21.1701 18.9422 21.8198 19.0342V27.1068C21.1998 26.9122 20.5435 26.7989 19.8586 26.7989C18.0962 26.801 16.4066 27.5021 15.1605 28.7484C13.9144 29.9947 13.2136 31.6844 13.2119 33.4467C13.212 34.8387 13.6519 36.1951 14.4689 37.3222L14.4706 37.3231ZM6.26428 38.6397C6.93975 40.3996 7.9478 41.9961 9.21034 43.3529C7.9221 41.9743 6.92735 40.371 6.26428 38.6397Z" fill="black"/>
  </svg>
</template>

<script>
export default {
  name: "TikTokIcon"
}
</script>

<style scoped>

</style>