<template>
  <div class="container d-flex align-items-center justify-content-center">
    <h2>{{ $t('loading') }}</h2>
  </div>
  <div class="container d-flex align-items-center justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only"></span>
        </div>
  </div>
</template>


<script>

export default {
name: "DefaultLoader",
  components:{}
}
</script>

<style scoped>

</style>